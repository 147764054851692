'use client';

import { type PropsWithChildren } from 'react';
import { ThemeProvider } from 'next-themes';
import RBThemeProvider from 'react-bootstrap/ThemeProvider';

export function Theme(props: PropsWithChildren) {
  return (
    <ThemeProvider attribute="data-bs-theme">
      <RBThemeProvider>{props.children}</RBThemeProvider>
    </ThemeProvider>
  );
}
